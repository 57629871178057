<template>
  <div>
    <v-progress-circular
      v-if="loadingAtualizacaoView"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-text-field
        v-model="search"
        v-if="resultado.lenght > 1"
        append-icon="mdi-magnify"
        class="ml-auto mb-5 break-search"
        label="Procurar"
        hide-details
        single-line
      />
      <v-data-table
        :headers="headers"
        :items="resultado"
        :search.sync="search"
        :mobile-breakpoint="800"
        :hide-default-footer="!resultado.lenght"
        no-data-text="Nenhuma linha de dados retornada pela consulta"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import DiagnosticosPreEtapa17Service from '@/services/DiagnosticosPreEtapa17Service';
export default {
  name: 'DiagnosticosPreEtapa17DataTable',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    viewDiagnostico: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getDadosViewDiagnostico();
  },
  data: () => ({
    loadingAtualizacaoView: false,
    search: null,
    resultado: []
  }),
  computed: {
    headers() {
      if (!this.resultado.length) {
        return [];
      }
      return Object.keys(this.resultado[0]).map((header) => ({
        text: header.toUpperCase(),
        value: header
      }));
    }
  },
  methods: {
    getDadosViewDiagnostico() {
      this.loadingAtualizacaoView = true;
      DiagnosticosPreEtapa17Service.getDadosViewDiagnostico(
        this.bdgdVersao.id,
        this.viewDiagnostico.view
      )
        .then(({ data }) => {
          this.resultado = data;
        })
        .catch((error) => {
          this.$toast.error(
            `Erro ao buscar os dados do diagnóstico para "${this.viewDiagnostico.nome}".`,
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.loadingAtualizacaoView = false));
    }
  }
};
</script>
