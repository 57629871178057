import APIService from './APIService';

export default {
  getViewsAtivas() {
    return APIService.apiCall().get('/diagnosticos-pre-etapa-17/get-views-ativas');
  },
  getDadosViewDiagnostico(bdgdVersaoId, viewDiagnostico) {
    return APIService.apiCall().get(`/diagnosticos-pre-etapa-17/get-dados-view-diagnostico/${bdgdVersaoId}/${viewDiagnostico}`);
  }
};
